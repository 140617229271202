import React from "react";

import { Button, Col, Row } from "antd";
import { Link } from "react-router-dom";
import i2space from "../../assets/images/I2space.png"
import {
  BrowserRouter as Router,
  useLocation,
  withRouter,
  Route,
  Switch,
} from "react-router-dom";
// import { useSytContext } from "../providers/SytProvider";
// import { AppConstants } from "../../helpers/constants";
import {
  FacebookOutlined,
  YoutubeOutlined,
  TwitterOutlined,
  InstagramOutlined,
} from "@ant-design/icons";
import "../footer/Footer.scss";
import { useAuthContext } from "../../common/providers/AuthProvider";

const Footer = () => {
  //   const { siteDetails } = useSytContext();
  //   const {
  //     isLogin: { flag, agent,isGuest },
  //     user,
  //     setUser,
  //     resetIsLogin,
  //   } = useAuthContext();
  //   let services = user?.Services ? user?.Services : [];
  const { pathname } = useLocation();

  return (
    <div id="footer">


      <div className="footer_bottom_section">
        <div className="footer_container">
          <div className="footer_links">
            <div className="footer_column">
              <p>Company</p>
              <ul>
                {/* {console.log(pathname, "path")} */}
                {pathname != "/" &&
                  <li><Link to="/">Home</Link></li>}
                {pathname != "/AboutUs" &&
                  <li><Link to="/AboutUs">About Us</Link></li>}
                
                {/* {pathname != "/disclaimer" &&
                  <li><Link to="/disclaimer">Disclaimer</Link></li>} */}
                {pathname != "/termsofconditions" &&
                  <li><Link to="/termsofconditions">Terms & Conditions</Link></li>}
              </ul>
            </div>

            <div className="footer_column">
              <p>Support</p>
              <ul>
                <li><Link to="/contact_us_form">Connect Us</Link></li>
                <li><Link to="/Refundpolicy">Refund Policy</Link></li>
                {pathname != "/privacypolicy" &&
                  <li><Link to="/privacypolicy">Privacy Policy</Link></li>}
                {/* <li><Link to="/termsofconditions">Terms & Conditions</Link></li> */}
              </ul>
            </div>

            {/* <div className="footer_column">
              <p>Contact Us</p>
              <ul>
                <li><i className="fa fa-phone"></i> +91 999999999</li>
                <li><i className="fa fa-envelope-o"></i> support@etravos.com</li>
              </ul>
            </div> */}
          </div>
          <div className="footer_t_section">
            <div className="footer_copyright">
              <span>A Product of <img className="i2s" src={i2space} alt="" width={30} /> space</span>
              <div style={{ display: "flex" }}>Copyright &copy; e<span style={{ color: "#f0802d", fontWeight: "600" }}>T </span>ravos. All rights reserved.</div>
            </div>

            <div className="footer_social_info">
              {/* Social Icons */}
              <a href="https://facebook.com" className="social_icon"><FacebookOutlined /></a>
              <a href="https://youtube.com" className="social_icon"><YoutubeOutlined /></a>
              <a href="https://twitter.com" className="social_icon"><TwitterOutlined /></a>
              <a href="https://instagram.com" className="social_icon"><InstagramOutlined /></a>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Footer;

import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  message,
  Card,
} from "antd";
// import axios from "axios";
import { useAuthContext } from "../../common/providers/AuthProvider";
import ApiClient from "../../helpers/ApiClient";
import logintop from "../../assets/images/login-top.png";
import EtravosLogo from "../../assets/images/etravosLogo.png";

import "./Login.scss";

const Login = (props) => {
  const [userID, setUserID] = useState(-1);
  const { setUser, setAccessToken } = useAuthContext();
  // const [ipAddress, setIpAddress] = useState(""); 
  const { updateIsLogin } = props.gscontext;
  const setChangeLoginFlag = (value) => updateIsLogin("flag", value);
  const setChangeDashboardFlag = (value) => updateIsLogin("role", value);

  const [isLoginForm, setIsLoginForm] = useState(true);

  // Fetch the user's public IP address install axios
  // const getIpAddress = async () => {
  //   try {
  //     const response = await axios.get("https://api.ipify.org?format=json"); // Fetch the IP using axios
  //     setIpAddress(response.data.ip);  // Set the IP address in state
  //   } catch (error) {
  //     console.error("Error fetching IP address:", error);
  //   }
  // };

  // Call getIpAddress when the component mounts
  // useEffect(() => {
  //   getIpAddress();
  // }, []);

  const onFinish = (data) => {
    
    data = {
      username: data.username,
      password: data.password,
      // ipAddress: ipAddress,  
      ipAddress:"",
      // ipAddress: null,
      loginType: "Web", 
    };

    ApiClient.post("user/login", data)
      .then((res) => {
        if (res.isSuccess === true) {
          setUser(res.data.user);
          setAccessToken(res.data.tokenId);

          if (res.data.user.isActive === false) {
            message.info("User is Inactive");
          } else {
            setChangeDashboardFlag(true);
          }
        } else if (res.status === 409) {
          message.error(res.message);
        } else {
          message.error(res.message);
        }
      })
      .catch((e) => {
        console.error("Login error:", e);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const showForgotForm = () => {
    setIsLoginForm(false);
  };

  const submitForm = (data) => {
    ApiClient.get(`user/forgotpassword?Username=${data.EmailID}`)
      .then((res) => {
        if (res.isSuccess === true) {
          message.success(res.message);
          setIsLoginForm(true);
        } else {
          message.error("User Not Found");
        }
      })
      .catch((error) => {
        console.error("Forgot password error:", error);
      });
  };

  return (
    <div className="login-con-banner">
      <Card className="login-card">
        {isLoginForm ? (
          <Form
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Row>
              <Col md={24} xs={24} className="py-2">
                <img className="login-otp-img" src={logintop} alt="search-img" />
                <div className="bc-admin-21 d-flex justify-content-center">
                  <img src={EtravosLogo} alt="search-img" />
                </div>
              </Col>

              <Col md={24} xs={24} className="otp-shadow-1">
                <Form.Item
                  className="otp-margin-bottom"
                  label="User Name"
                  name="username"
                  rules={[
                    { required: true, message: "Please input your username!" },
                  ]}
                >
                  <Input size="large" placeholder="User Name" />
                </Form.Item>

                <Form.Item
                  className="otp-margin-bottom"
                  label="Password"
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                >
                  <Input.Password size="large" placeholder="Password" />
                </Form.Item>
              </Col>

              <Col md={24} xs={24}>
                <Button className="otp-btn" htmlType="submit">
                  Log In
                </Button>
              </Col>
              <Col md={24} xs={24} className="mt-1 text-center">
                <a className="login-form-forgot" onClick={showForgotForm}>
                  Forgot Password!
                </a>
              </Col>
            </Row>
          </Form>
        ) : (
          <Form onFinish={submitForm} autoComplete="off" layout="vertical">
            <Row>
              <Col md={24} xs={24} className="py-2">
                <img className="login-otp-img" src={logintop} alt="search-img" />
                <div className="bc-admin-21">
                  <h5>Forgot Password</h5>
                  <p>Enter Email Id</p>
                </div>
              </Col>

              <Col md={24} xs={24} className="otp-shadow-1">
                <Form.Item
                  label="EMAIL ID"
                  name="EmailID"
                  rules={[
                    { required: true, message: "Please input your username!" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col md={24} xs={24}>
                <Button className="otp-btn" htmlType="submit">
                  Submit
                </Button>
              </Col>
              <Col md={24} xs={24} className="mt-1 text-center">
                <a className="login-form-forgot" onClick={() => setIsLoginForm(true)}>
                  Back To Login
                </a>
              </Col>
            </Row>
          </Form>
        )}
      </Card>
    </div>
  );
};

export default Login;
